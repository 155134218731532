// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-input {
    background-color: #e0e0e0 !important; 
    height: 50px !important; 
    border-radius: 5px !important; 
    border: none !important; 
    padding: 10px !important; 
    font-size: 16px !important; 
  }
  
  .custom-input::placeholder {
    color: #888888 !important;
  }
  
  .custom-input:focus {
    outline: none !important; 
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1) !important; 
  }

  .black-text{
    color: black;
 
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Signup/signup.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC;IACpC,uBAAuB;IACvB,6BAA6B;IAC7B,uBAAuB;IACvB,wBAAwB;IACxB,0BAA0B;EAC5B;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,wBAAwB;IACxB,mDAAmD;EACrD;;EAEA;IACE,YAAY;;EAEd","sourcesContent":[".custom-input {\n    background-color: #e0e0e0 !important; \n    height: 50px !important; \n    border-radius: 5px !important; \n    border: none !important; \n    padding: 10px !important; \n    font-size: 16px !important; \n  }\n  \n  .custom-input::placeholder {\n    color: #888888 !important;\n  }\n  \n  .custom-input:focus {\n    outline: none !important; \n    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1) !important; \n  }\n\n  .black-text{\n    color: black;\n \n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
