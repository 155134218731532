import React, { useEffect, useState, useCallback } from "react";
import "./UserDashboard.css";
import { SemiCircleProgress } from "react-semicircle-progressbar";
import { Button, Modal, Alert } from "react-bootstrap";
import axios from "axios";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import SettingsBtn from "../../SVG/Settings.svg";
import StarIcon from "../../SVG/Star.svg";
import UploadIcon from "../../SVG/Upload.svg";
import AlertIcon from "../../SVG/Alert.svg";
import PlanSummaryModal from "../helpers/PlanSummaryModal";
import UploadProgressModal from "../helpers/UploadProgressModal";



const SemiCircleProgressBar = ({ percentage }) => (
  <div className="semi-circle-container">
    <SemiCircleProgress
      percentage={percentage}
      size={{ width: 300, height: 200 }}
      strokeLinecap="butt"
      strokeWidth={18}
      strokeColor="#2087fe"
      hasBackground
      bgStrokeColor="#eee"
    />
    <p className="storage-used">Storage Used</p>
  </div>
);

const UserDashboard = () => {
  const [showModal, setShowModal] = useState(false);
  const [showProgressModal, setshowProgressModal] = useState(false);
  const [type, setType] = useState(false);
  // const [showPlanSummaryModal, setshowPlanSummaryModal] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  // const [progress, setProgress] = useState(70);
  const [convertedData, setConvertedData] = useState(null);
  const [fileResponse, setFileResponse] = useState(null);
  const percentage = 70;

  const toggleAlert = () => setShowAlert((prev) => !prev);

  // const processArrayBuffer = (arrayBuffer) => {
  //   // Assuming the response is an Excel file, we can use XLSX to parse it
  //   const workbook = XLSX.read(arrayBuffer, { type: "array" });
  //   console.log('workbook', workbook, arrayBuffer);
    
  
  //   const worksheet = workbook.Sheets[workbook.SheetNames[0]];
  //   const jsonData = XLSX.utils.sheet_to_json(worksheet);
  
  //   // Save the parsed data to state
  //   setConvertedData(jsonData);
  // };
  const handleFileUpload = useCallback(async (formData) => {
    setshowProgressModal(true);
    setType("Uploading");
    
    try {
      const response = await axios.post(
        "https://docgeniee.org/DOC/api/v1/invoices/download",
        formData,
        {
          responseType: 'arraybuffer', // Important: Set response type to 'arraybuffer'
          timeout: 300000,
        }
      );
      
      // console.log("Response received", response);
      
      // Create a Blob from the ArrayBuffer
      const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  
      // Create a temporary URL for the Blob
      const url = window.URL.createObjectURL(blob);
  
      // Create a temporary anchor element
      const a = document.createElement("a");
      a.href = url;
      a.download = `invoice_${Date.now()}.xlsx`; // Filename with timestamp
  
      // Append the anchor to the body
      document.body.appendChild(a);
  
      // Trigger a click on the anchor to start the download
      a.click();
  
      // Remove the anchor from the body
      document.body.removeChild(a);
  
      // Release the temporary URL
      window.URL.revokeObjectURL(url);
  
      if(response.status === 200){
        setshowProgressModal(false);
        setShowModal(false);
      }
  
    } catch (error) {
      setshowProgressModal(false);
      console.error("Error uploading file:", error);
    }
  }, []);
  

  const handleFileChange = async (event) => {
    const files = event.target.files;
    if (files.length === 0) return;

    const formData = new FormData();
    Array.from(files).forEach((file) => formData.append("files[]", file));

    await handleFileUpload(formData);
  };

  // const processFile = useCallback(async (fileData) => {
  //   setshowProgressModal(true);
  //   setType("Processing");
  //   try {
  //     const response = await axios.post("https://docgeniee.org/DOC/process", fileData);
      
  //     // if (response.data.status === "success") {
  //     //   setConvertedData(response.data.results);
  //     //   setType(false);
  //     // }
  //   } catch (error) {
  //   setshowProgressModal(false);
  //     console.error("Error processing file:", error);
  //   }
  // }, []);

const exportToExcel = useCallback(() => {
  if (!convertedData || Object.keys(convertedData).length === 0) {
    console.error("No data available to export");
    return;
  }

  const workbook = XLSX.utils.book_new();

  // Iterate through each sheet in the data
  Object.keys(convertedData.SheetNames).forEach(sheetName => {
    const sheetData = convertedData.Sheets[sheetName];
    const worksheet = XLSX.utils.aoa_to_sheet(sheetData);
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
  });

  // Write the workbook and save as a blob
  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
  saveAs(data, "invoice_data.xlsx");
}, [convertedData]);


  

  // useEffect(() => {
  //   if (fileResponse) {
  //     processFile({ filenames: fileResponse });
  //   }
  // }, [fileResponse, processFile]);

  // useEffect(() => {
  //   if (convertedData) {
  //     exportToExcel();
  //   }
  // }, [convertedData, exportToExcel]);

  return (
    <div className="d-flex justify-content-center align-items-center vh-100">
      <div className="dashboard-container" style={{ width: "500px" }}>
        {/* User Information Section */}
        <div className="card p-3 mb-3 user-info-section">
          <div className="d-flex align-items-center">
            <img
              src="https://via.placeholder.com/40"
              alt="User Avatar"
              className="rounded-circle me-3"
              style={{ width: "40px", height: "40px" }}
            />
            <div>
              <div className="fw-bold">User Name</div>
              <div className="text-muted">user@gmail.com</div>
            </div>
            <Button className="settings-btn" onClick={toggleAlert}>
              <img src={SettingsBtn} alt="Settings Icon" />
            </Button>
          </div>
        </div>
        {showAlert && (
          <Alert variant="danger" className="Alert alert-transition">
            <img
              src={AlertIcon}
              alt="Alert Icon"
              style={{ paddingRight: "20px", paddingLeft: "10px" }}
            />
            Plan expiring in 3 days
          </Alert>
        )}

        {/* Plan Details Section */}
        <div className="card p-4 plan-details-section">
          <h5 className="plan-details">Plan Details</h5>
          <div className="text-center mb-4 d-flex flex-row justify-content-between">
            <h5 className="fw-bold">Premium</h5>
            <button className="btn btn-warning">
              <img src={StarIcon} alt="Star Icon" /> Upgrade
            </button>
          </div>

          <div className="text-center">
            <SemiCircleProgressBar percentage={percentage} />
          </div>

          <div className="d-flex justify-content-around text-center mb-4">
            <div>
              <div className="fw-bold">10</div>
              <div className="text-muted">Pages Left</div>
            </div>
            <div>
              <div className="fw-bold">120</div>
              <div className="text-muted">Days Left</div>
            </div>
            <div>
              <div className="fw-bold">1GB</div>
              <div className="text-muted">Storage Left</div>
            </div>
          </div>
        </div>

        <div className="text-center mt-4">
          <button className="btn upload-btn w-50" onClick={() => setShowModal(true)}>
            <img src={UploadIcon} alt="Upload Icon" />Upload
          </button>
        </div>

        {/* Upload Modal */}
        <Modal show={showModal} onHide={() => setShowModal(false)} centered dialogClassName="custom-modal">
          <Modal.Header closeButton>
            <Modal.Title className="w-100 text-center upload-title">
              Upload Files
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-center">
            <div className="upload-icon-circle">
            <input
              type="file"
              id="file-upload"
              multiple
              accept="capture=camera,image/*,.pdf"
              style={{
                position: "absolute",
                width: "35%",
                height: "50%",
                opacity: 0,
                cursor: "pointer",
              }}  
              onChange={handleFileChange}
            />
              <img
                src={UploadIcon}
                alt="Upload Icon"
                style={{ fontSize: "5rem", color: "#fff" }}
                width={"40px"}
              />
            </div>
            <p className="mt-3" style={{ fontSize: "0.8rem", color: "#6c757d" }}>
              Drag and drop files here or{" "}
              <a
                href="#"
                style={{ fontSize: "0.8rem", color: "#007bff" }}
                onClick={(e) => {
                  e.preventDefault();
                  document.getElementById("file-upload").click();
                }}
                className="text-decoration-none"
              >
                Click here
              </a>
            </p>
          </Modal.Body>
        </Modal>
      </div>
      <UploadProgressModal show={showProgressModal} onHide={setshowProgressModal} type={type}/>
      {/* <PlanSummaryModal show={showPlanSummaryModal} onHide={handleClosePlanSummaryModal} percentage={percentage}/> */}
    </div>
  );
};

export default UserDashboard;
